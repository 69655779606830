<template>
  <div class="box_sorting">
    <strong class="screen_out">내역 검색하기</strong>
    <div class="list_sorting list_sorting_half">
      <div class="sort_left">
        <div class="item_sort">
          <em class="tit_txt">종류</em>
          <div class="cont_sorting">
            <div class="box_choice">
              <div class="item_choice" style="width: 77px">
                <input
                  id="taxBill"
                  v-model="searchFormSync.billType"
                  type="radio"
                  :disabled="!disableBill"
                  class="inp_choice"
                  :value="'taxBill'"
                  name="billType"
                /><!-- disabled 상태시 disabled 클래스 추가 -->
                <label class="lab_choice" for="taxBill">
                  <span class="ico_account ico_rdo" />세금계산서
                </label>
              </div>
              <div class="item_choice">
                <input
                  id="bill"
                  v-model="searchFormSync.billType"
                  type="radio"
                  :disabled="disableBill"
                  class="inp_choice"
                  :value="'bill'"
                  name="billType"
                /><!-- disabled 상태시 disabled 클래스 추가 -->
                <label class="lab_choice" for="bill">
                  <span class="ico_account ico_rdo" />계산서
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="item_sort">
          <em class="tit_txt">정산여부</em>
          <div class="cont_sorting">
            <div class="box_choice">
              <div class="item_choice" style="width: 77px">
                <input
                  id="N"
                  v-model="searchFormSync.inspectYn"
                  type="radio"
                  class="inp_choice"
                  :value="false"
                  name="inspectYn"
                /><!-- disabled 상태시 disabled 클래스 추가 -->
                <label class="lab_choice" for="N">
                  <span class="ico_account ico_rdo" />미정산
                </label>
              </div>
              <div class="item_choice">
                <input
                  id="Y"
                  v-model="searchFormSync.inspectYn"
                  type="radio"
                  class="inp_choice"
                  :value="true"
                  name="inspectYn"
                /><!-- disabled 상태시 disabled 클래스 추가 -->
                <label class="lab_choice" for="Y"> <span class="ico_account ico_rdo" />정산 </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sort_right">
        <div class="item_sort">
          <em class="tit_txt">증빙년월</em>
          <div class="cont_sorting">
            <div class="row_pack">
              <div class="box_picker">
                <DatePicker
                  :dateString.sync="searchFormSync.draftFromDate"
                  :disabled="flatpickrConfigDayDraftFrom.disabled"
                  @update:dateString="listenToOnChangeDayDraftFrom"
                />

                <span class="txt_bar">~</span>
                <DatePicker
                  :dateString.sync="searchFormSync.draftToDate"
                  :disabled="flatpickrConfigDayDraftTo.disabled"
                  @update:dateString="listenToOnChangeDay"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="item_sort">
          <em class="tit_txt">거래처명</em>
          <!-- {{titleSearch}} -->
          <div class="cont_sorting">
            <div class="group_search">
              <!-- <comm-opt
                class="w208"
                :classList="['type_short']"
                :optionDesc="'검색 선택상자'"
                :optionName="searchForm.searchType.name"
                :optionList="SEARCH_TYPE_SELECTBOX_LIST"
                :eventName="'select'"
                @select="selectSearchType"
              ></comm-opt> -->
              <div class="group_input">
                <!-- <Input
                  :value.sync="searchForm.searchKeyword"
                  @onKeyUpEnter="keypress"/> -->
                <Input :value.sync="searchFormSync.partnerNm" @onKeyUpEnter="keypress" />
              </div>
            </div>
          </div>
        </div>
        <div class="item_sort">
          <em class="tit_txt">이메일</em>
          <!-- {{titleSearch}} -->
          <div class="cont_sorting">
            <div class="group_search">
              <!-- <comm-opt
                class="w208"
                :classList="['type_short']"
                :optionDesc="'검색 선택상자'"
                :optionName="searchForm.searchType.name"
                :optionList="SEARCH_TYPE_SELECTBOX_LIST"
                :eventName="'select'"
                @select="selectSearchType"
              ></comm-opt> -->
              <div class="group_input">
                <!-- <Input
                  :value.sync="searchForm.searchKeyword"
                  @onKeyUpEnter="keypress"/> -->
                <Input :value.sync="searchForm.email" @onKeyUpEnter="keypress" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- // list_sorting -->
    <div class="wrap_btn">
      <button type="button" class="btn_medium btn_primary" @click.prevent="clickSearchButton">
        검색
      </button>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import CommOpt from "@/_approval/components/common/CommOpt";
import Input from "@/_approval/components/common/input/Input";
// import { default as CommFlatpickrMixin } from '@/_approval/mixins/common/commFlatpickr.js';

import DatePicker from "@/components/common/calendar/DatePicker";

export default {
  name: "PopBillFilter",
  components: {
    CommOpt,
    Input,
    DatePicker,
  },
  // mixins: [CommFlatpickrMixin],
  props: {
    searchForm: Object,
    disableBill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // searchForm:{
      //   billType:'taxBill',
      //   inspectYn:false,
      //   draftFromDate: '',
      //   draftToDate: '',
      //   // searchType: { code: 'subject', name: '구매요청명' },
      //   //searchKeyword:''
      // },
      flatpickrConfigDayDraftFrom: { disabled: false },
      flatpickrConfigDayDraftTo: { disabled: false },
      flatpickrConfigDayCompleteFrom: {},
      flatpickrConfigDayCompleteTo: {},
      // SEARCH_TYPE_SELECTBOX_LIST: [
      //   { code: 'requestCid', name: '구매요청번호' },
      //   { code: 'title', name: '구매요청명' },
      //   { code: 'requester', name: '구매요청자' },
      //   { code: 'd', name: '결재대기자' },
      // ],
    };
  },
  computed: {
    searchFormSync: {
      get() {
        return this.searchForm;
      },
      set(value) {
        this.$emit("update:searchForm", value);
      },
    },
  },
  create() {
    // this.flatpickrConfigDayDraftFrom = { ...this.flatpickrConfigDay };
    // this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDay };
    //this.flatpickrConfigDayCompleteFrom = { ...this.flatpickrConfigDay };
    //this.flatpickrConfigDayCompleteTo = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayDraftFrom = Object.assign(
      this.flatpickrConfigDayDraftFrom,
      this.flatpickrConfigMonth,
    );

    this.flatpickrConfigDayDraftTo = Object.assign(
      this.flatpickrConfigDayDraftTo,
      this.flatpickrConfigMonth,
    );
  },
  methods: {
    listenToOnChangeDay(dateString, dateObj) {},
    listenToOnChangeDayDraftFrom(dateString, dateObj) {
      this.flatpickrConfigDayDraftTo.minDate = dateString;
      this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDayDraftTo };

      // this.calculateDateEnd = null;
    },
    listenToOnChangeDayCompleteFrom(dateString, dateObj) {
      this.flatpickrConfigDayCompleteTo.minDate = dateString;
      this.flatpickrConfigDayCompleteTo = {
        ...this.flatpickrConfigDayCompleteTo,
      };
    },
    selectSearchType(searchType) {
      this.searchForm.searchType = searchType;
    },
    clickSearchButton() {
      this.$emit("select-connector", this.searchForm); // 부모 함수(selectDocList)에게 넘김
    },
    keypress(key) {
      // if (key.which === 13) {
      //   this.$emit('select-connector', this.searchForm);
      // }
    },
  },
};
</script>
